<template>
  <div v-if="dealer" class="world academy trainings">
    <div class="wrapper-title">
      <div class="container">
        <div class="grid">
          <div>
            <router-link :to="{name: 'ww.dashboard'}" class="text-wilmsLightBlue block">&larr; {{ $t('Back to overview') }}</router-link>
            <h1>{{ $t('Wilms Academy') }}</h1>
            <div v-if="introBody" class="intro" v-html="getTranslation(introBody).value"></div>
          </div>

          <div class="image">
            <img alt="Academy" src="@/assets/img/academy.png">
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper-trainings">
      <div class="container">
        <div class="grid">
          <div class="overview">
            <h3>{{ $t('Alle opleidingen') }}</h3>

            <div class="trainings" v-if="!loading">
              <div v-for="training in trainings" :key="training.id" class="training">
                <div class="image">
                  <img v-if="images[training.file_id]" :src="images[training.file_id].url" alt="">
                </div>
                <div class="content">
                  <h3>{{ getTranslation(training).title }}</h3>
                  <div class="info" v-html="getTranslation(training).summary"></div>
                  <router-link :to="{ name: 'ww.training', params: { id: training.id } }" class="btn">{{ $t('Bekijk opleiding') }}</router-link>
                </div>
              </div>
            </div>

            <div class="trainings" v-if="loading">
              <div class="training" :key="i" v-for="i in 4">
                <div class="image loading loading-full"></div>
                <div class="content">
                  <h3 class="loading loading-default"></h3>
                  <div class="info loading loading-120"></div>
                  <div class="loading w-1/4 loading-default"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="bookings">
            <template v-if="myNextBookings.length > 0">
              <h3>{{ $t('Mijn opleidingen') }}</h3>

              <div class="next-bookings">
                <div v-for="booking in myNextBookings" :key="booking.id" class="booking" @click="openBooking(booking.id)">
                  <h3>{{ getTranslation(booking.event.training).title }}</h3>
                  <div class="time">{{ datetime.fromISO(booking.event.event_start).setLocale(activeLocale).toFormat('dd/MM/yyyy  HH:mm') }} - {{ datetime.fromISO(booking.event.event_end).setLocale(activeLocale).toFormat('HH:mm') }}</div>

                  <div class="participants-header">
                    <span>{{ $t('Deelnemers') }}</span> <img alt="" src="@/assets/img/arrow_right.svg" width="6">
                  </div>
                  <div :class="{ 'open': participantsShown.includes(booking.id) }" class="participants">
                    <div v-for="(participant, j) in booking.participants" :key="j" class="participant">
                      {{ participant.first_name }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="myPastBookings.length > 0">
              <h3>{{ $t('Mijn verlopen opleidingen') }}</h3>
              <div class="past-bookings">
                <div v-for="booking in myPastBookings" :key="booking.id" class="booking">
                  <h3>{{ getTranslation(booking.event.training).title }}</h3>
                  <div class="time">{{ datetime.fromISO(booking.event.event_start).setLocale(activeLocale).toFormat('dd/MM/yyyy  HH:mm') }} - {{ datetime.fromISO(booking.event.event_end).setLocale(activeLocale).toFormat('HH:mm') }}</div>
                </div>
              </div>
            </template>

            <div v-if="myPastBookings.length === 0 && myNextBookings.length === 0" class="no-trainings">
              <div class="icon">
                <Icon color="#0F1D61" height="22" icon="bi:calendar-x"/>
              </div>
              {{ $t('U heeft zich nog niet ingeschreven voor een opleiding.') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { Icon } from '@iconify/vue2'
import { DateTime } from 'luxon'

export default {
  components: {
    Icon
  },
  data () {
    return {
      introBody: null,
      trainings: [],
      myBookings: [],
      participantsShown: [],
      datetime: DateTime,
      loading: true
    }
  },
  async mounted () {
    await this.setDealer()
    this.introBody = (await ApiService.fetchGlobal('academy', 'overview.intro')).data
    ApiService.fetchPublishedTrainings().then(async res => {
      this.trainings = res.data.trainings

      this.$store.dispatch('fetchImages', { objects: this.trainings, value: 'file_id' })

      this.loading = false
    })
    ApiService.fetchMyBookings(this.dealer.id).then(res => {
      this.myBookings = res.data
    })
  },
  methods: {
    openBooking (id) {
      if (this.participantsShown.includes(id)) {
        this.participantsShown = []
      } else {
        this.participantsShown = []
        this.participantsShown.push(id)
      }
    }
  },
  computed: {
    images () {
      return this.$store.getters.getImages
    },
    myPastBookings () {
      return this.myBookings.filter(booking => DateTime.fromISO(booking.event.event_start) < DateTime.now())
    },
    myNextBookings () {
      return this.myBookings.filter(booking => DateTime.fromISO(booking.event.event_start) >= DateTime.now())
    }
  }
}
</script>
